import { auth } from '../firebase'
import api, { ApiErrorHandler } from './index'

async function getConfig () {
  const token = await auth.currentUser.getIdToken(true)
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

const getCurrentQuestion = async function () {
  const config = await getConfig()
  let res = null

  try {
    res = await api.get('/clans/test/currentQuestion', config)
  } catch (e) {
    ApiErrorHandler(e.message)
  }

  return res.data
}

const sendQuestionResponse = async function (response, questionId) {
  const config = await getConfig()
  let res = null

  try {
    res = await api.post(`/clans/test/response?response=${response}&questionId=${questionId}`, {}, config)
  } catch (e) {
    ApiErrorHandler(e.message)
  }

  return res.data
}

export {
  getCurrentQuestion,
  sendQuestionResponse
}

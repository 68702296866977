<script>
import { differenceInDays, formatDistanceToNow } from 'date-fns'
import { fr } from 'date-fns/locale'
import JsBarcode from 'jsbarcode'

export default {
  props: {
    profile: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    status () {
      const { isFollow, isSubscribed } = this.profile
      return isFollow ? `Moustachu ${isSubscribed ? 'Sub' : ''}` : 'Inconnu'
    },

    followLevel () {
      const { followedAt } = this.profile
      return differenceInDays(new Date(), new Date(followedAt))
    },

    clan () {
      if (this.profile.clanId) {
        return this.$store.getters.getClanById(this.profile.clanId)
      } else {
        return null
      }
    }
  },

  mounted () {
    this.initBarcode()
  },

  methods: {
    formateDate (date) {
      return formatDistanceToNow(new Date(date), { locale: fr })
    },

    initBarcode () {
      JsBarcode(this.$refs.barcode, this.profile.username, {
        lineColor: '#0aa',
        width: 4,
        height: 100,
        displayValue: false
      })
    }
  }
}
</script>

<template>
  <div class="member-card">
    <h4>Carte de Membre</h4>
    <div class="member-card-container">
      <figure
        class="member-card__avatar"
        aria-label="User Avatar"
      >
        <img
          alt="User Avatar"
          :src="profile.photoUrl"
        >
        <p v-if="profile.isFollow">
          {{ status }}<br>
          LVL&nbsp;<strong>{{ followLevel }}</strong>
        </p>
      </figure>

      <ul class="member-card__details">
        <li class="member-card__details-username mrg-bottom">
          <strong>{{ profile.displayName }}</strong>
        </li>
        <li>
          <UiIcon
            name="triforce"
            :color="clan && clan.color"
          />
          {{ clan && clan.displayName || 'Pas de Clan' }}
        </li>
        <li>
          <UiIcon name="badge-account" />
          membre depuis {{ formateDate(profile.createdAt) }}
        </li>
      </ul>
    </div>

    <svg
      ref="barcode"
      class="barcode"
    />
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.member-card {
  background-color: $grey-100;
  color: $dark;
  overflow: hidden;
  border-radius: $gutter-xs;

  min-width: 290px;
  max-width: 300px;

  &-container {
    padding: $gutter;
  }

  @include card(2);

  h4 {
    text-transform: uppercase;
    text-align: center;
    padding: $gutter 0;
    background-color: $primary-color;
    color: $white;
  }

  strong {
    font-weight: 500;
  }

  .barcode {
    height: auto;
    width: 100%;
  }

  &__avatar {
    $avatar-size: 5rem;
    display: flex;
    width: 100%;
    height: $avatar-size;

    img {
      width: $avatar-size;
      height: $avatar-size;
      border-radius: 50%;
    }

    p {
      font-size: $font-size-2;
      margin-left: $gutter;
      line-height: 1.5rem;
    }
  }

  &__details {
    padding: $gutter 0;
    li {
      line-height: 1.1rem;
    }

    &-username {
      font-size: $font-size-2;
    }
  }
}
</style>

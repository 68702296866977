<script>
import anime from 'animejs'

export default {
  name: 'NumberAnimation',

  props: {
    number: {
      type: Number,
      default: 0
    }
  },

  watch: {
    number (newValue, prevValue) {
      anime({
        targets: this.$refs.el,
        color: [
          {
            value: ['#fff', '#E91E63'],
            duration: 300
          }, {
            value: ['#E91E63', '#fff'],
            duration: 300
          }
        ],
        innerHTML: [prevValue, newValue],
        round: 1,
        easing: 'linear'
      })
    }
  }
}
</script>

<template>
  <span
    ref="el"
    class="number-transition"
  >
    {{ number }}
  </span>
</template>

<style lang="scss">
.number-transition {}
</style>

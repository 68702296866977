<script>
import ClanLeaderboardCard from '@/components/ClanLeaderboardCard'

export default {
  name: 'Clans',

  components: {
    ClanLeaderboardCard
  }
}
</script>

<template>
  <div class="clans">
    <h1>Clans</h1>
    <h3>Page des Clans <i v-emoji="'🚧'" /> En cours de construction</h3>
    <!-- <section class="clans__main">
      <p>Description / Lore / Histoire des clans</p>
      <h3>Choisir son clan</h3>
    </section> -->

    <!-- <UiButton light>
      Faire le T.E.S.T. des clans
    </UiButton> -->

    <ClanLeaderboardCard />

    <UiButton
      class="mrg-top"
      :to="{ name: 'platform' }"
      type="primary"
      exact
    >
      Retourner au QG
    </UiButton>
  </div>
</template>

<style lang="scss">
  @import '~@/assets/style/variables';

  .clans {
    &__main {
      background-color: $dark;
    }
  }
</style>

<script>
import { mapState } from 'vuex'
import ClanCard from '@/components/ClanCard'
import ClansTestQuestions from './ClansTestQuestions'

export default {
  name: 'ClansTest',

  components: {
    ClansTestQuestions,
    ClanCard
  },

  data () {
    return {
      loading: true,
      error: null
    }
  },

  computed: {
    ...mapState({
      profile: s => s.profile.profile
    }),

    canPassTheTest () {
      return this.profile.isSubscribed || this.profile.isBoughtClanAccess
    },

    clan () {
      if (this.profile.clanId) {
        return this.$store.getters.getClanById(this.profile.clanId)
      } else {
        return null
      }
    }
  }
}
</script>

<template>
  <div class="clans-test">
    <h2 class="box-container-sm">
      Le T.E.S.T.<br><small>(Technologie d'Examination Super Talentueuse)</small>
    </h2>

    <section
      v-if="!canPassTheTest"
      class="text-center mrg-top"
    >
      <h3><i v-emoji="'🚫'" />Tu n'as pas encore accès au T.E.S.T.</h3>
      <p>Il te faut payer ta cotisation d'entrée pour pouvoir faire le T.E.S.T.</p>
      <UiButton
        class="mrg-top"
        :to="{ name: 'platform' }"
        type="primary"
        exact
      >
        Retourner au QG
      </UiButton>
    </section>

    <section
      v-else-if="profile.clanId && clan"
      class="text-center mrg-top"
    >
      <h3>Bravo ! <i v-emoji="'🎉'" /><br> voici ton résultat au T.E.S.T.</h3>
      <ClanCard
        class="box-center"
        :clan-id="profile.clanId"
      />

      <UiButton
        class="mrg-top"
        :to="{ name: 'platform' }"
        type="primary"
        exact
      >
        Retourner au QG
      </UiButton>
    </section>

    <section
      v-else
      class="box-container-sm"
    >
      <p class="mrg-top-sm">
        Une suite de questions vont s'afficher sur cette page, réponds à chacune de celles-ci en sélectionnant une des 3 propositions affichées.<br>
        Une fois que tu auras répondu aux 16 questions tu pourras connaitre le clan auquel tu appartiens.<br>
      </p>
      <!-- <p>Vérification du paiment de cotisation des Clans</p> -->

      <ClansTestQuestions
        class="mrg-top-lg"
        @done="$store.dispatch('getProfile')"
      />

      <p class="text-help block-help mrg-top-lg">
        <strong><i v-emoji="'🚧'" /> Attention :</strong><br>
        le questionnaire est réalisable une seule et unique fois. les réponses à châques questions sont enregistrés au fur et à mesure que tu y répondras tu peux donc reprendre le questionnaire où tu en étais à n'importe quel moment.
      </p>
    </section>
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.clans-test {
  h2 {
    color: $dark;
  }
}
</style>

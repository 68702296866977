<script>
import { mapState, mapActions } from 'vuex'
import NumberAnimation from '@/components/NumberAnimation'
import LogoMekajiki from '@/components/LogoMekajiki'
import LogoInoshishi from '@/components/LogoInoshishi'
import LogoKarasu from '@/components/LogoKarasu'
// import { db } from '@/firebase'

export default {
  name: 'ClanLeaderboardCard',

  components: {
    NumberAnimation,
    LogoMekajiki,
    LogoInoshishi,
    LogoKarasu
  },

  data () {
    return {
      loading: false,
      lastValues: {}
    }
  },

  computed: {
    ...mapState({
      clans: s => s.platform.clans
    })
  },

  created () {
    this.unsubscribe = null
    this.subClans()
  },

  beforeDestroy () {
    this.unsubClans()
  },

  methods: {
    ...mapActions(['subClans', 'unsubClans'])
  }
}
</script>

<template>
  <div class="leaderboard-card">
    <h4>Leaderboard</h4>

    <UiIcon
      v-if="loading"
      class="ui-icon-spin"
      name="loading"
      color="#fff"
    />

    <div
      v-else
      class="box-row flex"
    >
      <div
        v-for="clan in clans"
        :key="clan.id"
        class="leaderboard-card__clan"
      >
        <component
          :is="`logo-${clan.id}`"
          :title="clan.displayName"
        />
        <h3>
          <NumberAnimation :number="clan.totalPoints" />
          <small>pts</small>
        </h3>
        <h3>
          <UiIcon
            name="account"
            color="#fff"
          />
          <NumberAnimation :number="clan.totalMembers" />
        </h3>
      </div>
    </div>

    <!-- <UiButton light>
      Voir le leaderboard
    </UiButton> -->
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.leaderboard-card {
  background-color: $dark;
  color: $white;
  padding: $gutter;
  border-radius: $gutter-xs;

  h4 {
    text-transform: uppercase;
  }

  &__clan {
    text-align: center;

    svg {
      max-width: 100px;
    }

    h3 {
      small {
        font-weight: 100;
        font-size: 0.8rem;
      }
    }
  }

  @include card(2);
}
</style>

<script>
import { mapState } from 'vuex'
import { auth } from '@/firebase'
import { ApiErrorHandler } from '@/api'
import { getTwitchTokenWithCode } from '@/api/twitch'

export default {
  name: 'PlatformConnect',

  data () {
    const { query } = this.$route

    return {
      loading: false,
      hasCode: query.code && query.code.length
    }
  },

  computed: mapState({
    isLogged: s => (s.user.uid !== null),
    profile: s => (s.profile.profile)
  }),

  created () {
    const { hasCode, isLogged } = this

    if (hasCode) {
      this.loading = true
      this.sendTwitchAuthCode(this.$route.query.code)
    }

    if (isLogged || !hasCode) {
      this.$router.push({ name: 'platform' })
    }
  },

  methods: {
    async sendTwitchAuthCode (code) {
      try {
        const { data } = await getTwitchTokenWithCode(code)

        try {
          const userCredential = await auth.signInWithCustomToken(data.token)

          this.$router.push({ name: 'platform' })
          this.$snack.push({
            message: `Vous êtes connecté en tant que ${userCredential.user.displayName}.`,
            type: 'success'
          })
        } catch (error) {
          ApiErrorHandler(error)

          this.$router.push({ name: 'home' })
          this.$snack.push({
            message: 'l\'authentification à rencontré des soucis, contactez le support.'
          })
        }
      } catch (error) {
        ApiErrorHandler(error)

        this.$router.push({ name: 'home' })
        this.$snack.push({
          message: 'une erreur est survenue avec l\'authentification de Twitch, contactez le support.'
        })
      }

      this.loading = false
    }
  }
}
</script>

<template>
  <div class="platform-connect">
    <h2>Connection à votre compte en cours...</h2>
    <UiIcon
      v-if="hasCode && loading"
      class="ui-icon-spin"
      name="loading"
      color="#fff"
    />
  </div>
</template>

<style lang="scss">
  .platform-connect {}
</style>

<script>
import { mapState } from 'vuex'
import ViewContainer from '@/components/ViewContainer'
import MainTitle from '@/components/MainTitle'
import HexagonBackground from '@/components/HexagonBackground'

export default {
  name: 'Platform',

  components: {
    ViewContainer,
    MainTitle,
    HexagonBackground
  },

  computed: {
    ...mapState({
      loading: s => s.platform.loading
    })
  },

  created () {
    this.$store.dispatch('getClans')
  }
}
</script>

<template>
  <ViewContainer class="platform">
    <HexagonBackground />

    <MainTitle>
      headquarters&nbsp;| <small>Quartier&nbsp;Général</small>
    </MainTitle>

    <router-view
      v-if="!loading"
      class="sub-view box-container"
    />
  </ViewContainer>
</template>

<style lang="scss">
  @import '~@/assets/style/variables';

  .platform {
    background-color: $pink;
    // background-image: url('/img/bgs/bg_japan_004.png');
    // background-position: right bottom;
    // background-repeat: no-repeat;
    // background-size: 750px auto;
  }
</style>

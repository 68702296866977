<script>
import {
  getCurrentQuestion,
  sendQuestionResponse
} from '@/api/clans'

export default {
  name: 'ClansTestQuestions',

  data () {
    return {
      loading: true,
      question: null,
      position: '00',
      isDone: false,
      error: null
    }
  },

  watch: {
    isDone (newValue) {
      if (newValue) {
        this.$emit('done')
      }
    }
  },

  created () {
    this.getCurrentQuestion()
  },

  // on init check if sub or if user have paid for the test
  methods: {
    async getCurrentQuestion () {
      try {
        const { data, done, position } = await getCurrentQuestion()

        this.isDone = done || false
        this.position = position || null
        this.question = data
      } catch (error) {
        this.question = null
        console.log(error)

        this.$snack.push({
          type: 'error',
          message: error.message
        })
      }

      this.loading = false
    },

    async selectResponse (responseId) {
      const { id } = this.question

      this.loading = true
      try {
        await sendQuestionResponse(responseId, id)

        this.$snack.push({
          type: 'success',
          message: 'Réponse enregistré.'
        })

        await this.getCurrentQuestion()
      } catch (error) {
        console.log(error)

        this.$snack.push({
          type: 'error',
          message: error.message
        })
      }
    }
  }
}
</script>

<template>
  <div class="box-container-sm question">
    <p v-if="loading && !question">
      <UiIcon
        class="ui-icon-spin"
        name="loading"
        size="lg"
      />
      Chargement du protocol de T.E.S.T. (Technologie d'Examination Super Talentueuse)
    </p>

    <div
      v-if="isDone"
      class="question__done pddg-md"
    >
      <h3>Vous avez déjà finis le T.E.S.T</h3>
      <UiButton
        :to="{ name: 'platform' }"
        type="primary"
        exact
      >
        Retourner au QG
      </UiButton>
    </div>

    <div v-if="question">
      <UiLoader
        v-if="loading"
        class="relatif"
      />

      <p>{{ position }} / 16</p>

      <p class="question__question">
        {{ question.text }}
      </p>

      <ul
        v-for="(answer, answerId) in question.answers"
        :key="answerId"
        class="question__answers"
      >
        <li>
          <UiButton
            @click="selectResponse(answerId)"
          >
            {{ answer }}
          </UiButton>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.question {
  position: relative;
  background-color: $white;
  color: $dark;
  padding: $gutter $gutter-md;
  text-align: center;

  border-radius: 5px;
  @include card(3);

  &__question {
    font-size: $font-size-3;
    line-height: 1.2em;
    padding-bottom: $gutter-md;
  }

  ul {
    li {
      margin-bottom: $gutter-sm;
    }
  }
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    clanId: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapState({
      loading: s => s.platform.loadingClans
    }),

    clan () {
      if (this.clanId) {
        return this.$store.getters.getClanById(this.clanId)
      } else {
        return null
      }
    }
  }
}
</script>

<template>
  <div class="clan-card">
    <h4>{{ clanId ? 'Mon Clan': 'Rejoignez un Clan' }}</h4>

    <UiIcon
      v-if="loading"
      class="ui-icon-spin"
      name="loading"
      color="#fff"
    />

    <div v-else>
      <div v-if="clan">
        <h5>{{ clan.displayName }}</h5>
        <div
          class="clan-card__picture"
          :style="{ backgroundColor: clan.color }"
        >
          {{ clan.displayName[0] }}
        </div>
      </div>

      <div>
        <UiButton
          v-if="!clanId"
          icon="triforce"
          type="primary"
          :to="{ name: 'clans-test' }"
          light
        >
          je veux faire parti d'un clan
        </UiButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.clan-card {
  background-color: $dark;
  color: $white;
  padding: $gutter;
  border-radius: $gutter-xs;
  width: fit-content;

  h4 {
    text-transform: uppercase;
  }

  &__picture {
    height: 300px;
    width: 300px;
    background-color: $pink-900;
    overflow: hidden;

    font-size: 25rem;
    line-height: 25rem;

    border-radius: 5px;
  }

  @include card(2);
}
</style>

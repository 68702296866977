<script>
import { mapState } from 'vuex'
import MemberCard from '@/components/MemberCard'
import ClanCard from '@/components/ClanCard'
import ClanLeaderboardCard from '@/components/ClanLeaderboardCard'

export default {
  name: 'PlatormHome',

  components: {
    MemberCard,
    ClanCard,
    ClanLeaderboardCard
  },

  computed: {
    ...mapState({
      profile: s => s.profile.profile,
      loadingProfile: s => s.profile.loading
    })
  }
}
</script>

<template>
  <div class="platform-home">
    <h2>Bienvenue dans le QG, {{ profile.displayName }}!</h2>

    <UiIcon
      v-if="loadingProfile"
      class="ui-icon-spin"
      name="loading"
      color="#fff"
    />

    <section
      v-else
      class="box-row"
    >
      <MemberCard :profile="profile" />

      <ClanCard :clan-id="profile.clanId" />

      <ClanLeaderboardCard class="box-spacing" />
    </section>

    <div class="box-row line-up">
      <div class="discord">
        <iframe
          src="https://discord.com/widget?id=515169860536369152&theme=dark"
          width="350"
          height="500"
          allowtransparency="true"
          frameborder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.platform-home {
  .line-up {
    border-top: 1px solid rgba(236,232,225,.4);
  }
}
</style>
